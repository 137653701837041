import React, { useState } from "react";
import * as Sentry from "@sentry/node";
import axios from "axios";
import { Spin, message } from "antd";
import {
  CarouselSection,
  CarouselImage,
  NewPetsSection,
  ReasonSection,
  VoiceSection,
  ColumnSection,
  PictureBookSection,
} from "../components";
import { API, NewsList } from "@project/shared";
import styled from "styled-components";
import { QueryFunction, useQuery } from "react-query";

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
`;
const Space = styled.div`
  height: 240px;
  width: 100%;
`;
const SpaceNewPets = styled.div`
  height: 20px;
  width: 100%;
`;

const fetchHomePageData: QueryFunction<any> = async ({ queryKey }) => {
  const page = queryKey[1];
  const pageSize = queryKey[2];
  const [newPetsResponse, VoicesResponse, columnResponse] = await axios.all([
    API.get("/consumer/pets", {
      params: {
        pageSize: 8,
        is_new: 1,
      },
    }),
    API.get(`/consumer/voices`, {
      params: { page, pageSize },
    }),
    API.get(`/consumer/columns`, {
      params: {
        pageSize: 4,
      },
    }),
  ]);
  return [newPetsResponse, VoicesResponse, columnResponse];
};

const fetchCarouselData = async () => {
  const response = await API.get(`/consumer/top-images`);
  return response;
};

export default function Home() {
  const [page] = useState(1);
  const [pageSize] = useState(3);

  const { isLoading: loading, data } = useQuery(
    ["homePageData", page, pageSize],
    fetchHomePageData,
    {
      staleTime: Number.POSITIVE_INFINITY,
      onError: (err: Error) => {
        Sentry.captureException(err);
        message.error(err?.message || "Some thing went wrong");
      },
    }
  );

  const { data: carouselData } = useQuery(["carousel"], fetchCarouselData, {
    staleTime: Number.POSITIVE_INFINITY,
    select: (response) => {
      const win = window,
        doc = document,
        docElem = doc.documentElement,
        body = doc.getElementsByTagName("body")[0],
        width = win.innerWidth || docElem.clientWidth || body.clientWidth;
      if (response && response.data) {
        const targetDevice = width > 768 ? "pc" : "smartphone";
        const images: CarouselImage[] = response.data.filter((data) => {
          return data.device === targetDevice && data.image !== "";
        });
        return images;
      }
      return [];
    },
    onError: Sentry.captureException,
  });

  if (loading) {
    return (
      <LoaderWrapper>
        <Spin size="large" className="loader" />
      </LoaderWrapper>
    );
  }

  const newPets = data?.[0]?.data?.rows;
  const voiceData = data?.[1]?.data?.rows;
  const columnsData = data?.[2]?.data?.rows;

  return (
    <>
      <CarouselSection images={carouselData} />
      {newPets && newPets.length ? (
        <NewPetsSection newPets={newPets} />
      ) : (
        <SpaceNewPets />
      )}
      <ReasonSection />
      {voiceData && voiceData.length !== 0 && (
        <VoiceSection voiceData={voiceData} />
      )}
      {columnsData && columnsData.length === 0 ? (
        <Space />
      ) : (
        <ColumnSection columnData={columnsData} />
      )}
      <PictureBookSection />
      <NewsList />
    </>
  );
}
